import Vue from "vue";
import VueRouter from "vue-router";
import TelaLogin from "../views/TelaLogin";
import EstiloPublico from "../views/layouts/Publico";
import HTML_404 from "@/components/404.vue";
// import { isUsuarioAutenticado, possuiPermissao } from "../seguranca/helpers";
import SemPermissao from "../views/layouts/SemPermissao";


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "basico" */'../views/layouts/Logado'),
        children: [
            {
                path: '/home',
                component: () => import(/* webpackChunkName: "basico" */'../views/Home.vue'),
            },
            {
                path: '/avaliacao/:processo_avaliacao_id/:servidor_id',
                component: () => import(/* webpackChunkName: "basico" */'../views/Avaliacao.vue'),
            },
            {
                path: '/estagio',
                component: () => import(/* webpackChunkName: "basico" */'../views/Estagio.vue'),
            },


            // Exemplo de uma rota de Mosaico
            {
                path: '/parametros',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/mosaico'),
            },

            // Exemplo de uma rota de pesquisa onde se mostra o grid. Ela vai ler por padrão o arquivo index.vue.
            {
                path: '/parametros/sefa',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/classificacao-sefa'),
            },
            // Exemplo de uma rota para a tela de cadastro.
            {
                path: '/parametros/sefa/create',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/classificacao-sefa/create'),
            },

            // Exemplo de uma rota de ediçao.
            {
                path: 'parametros/sefa/:id/edit',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/classificacao-sefa/create'),
            },

            // Exemplo de uma rota de visualizacao.
            {
                path: 'parametros/sefa/:id/view',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/classificacao-sefa/create'),
            },

            {
                path: '/tela',
                component: () => import(/* webpackChunkName: "tela" */'../views/seguranca/tela/index'),
                param: {
                    seguranca: true
                }
            }
        ],
    },

    {
        path: '/autenticacao',
        component: EstiloPublico,
        children: [
            {
                path: '',
                component: TelaLogin,
            },
            {
                path: '/sem-permissao',
                component: SemPermissao
            },
            {
                path: '/solicitar-acesso',
                component: () => import(/*webpackChunkName: "acesso" */ '../views/usuario/solicitar-acesso'),
            },
            {
                path: '/atualizar-senha',
                component: () => import(/*webpackChunkName: "atualizar-senha" */ '../views/site/atualizar-senha'),
            }
        ],
    },
    {
        path: '/:catchAll(.*)',
        component: HTML_404,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach((to, from, next) => {
    //quem não está autenticado volta para a tela de login
    // if (!isUsuarioAutenticado() && (to.path !== '/autenticacao' && to.path !== '/atualizar-senha')) {
    //     next({ path: '/autenticacao' })
    // } else if (!isUsuarioAutenticado() && to.path === '/autenticado') {
    //     next();
    // } else if (possuiPermissao(to.path)) {
    //     next();
    // } else {
    //     alert('Sem permissão de acesso!')
    // }
// });

export default router;
